import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import XIcon from '../assets/x-icon.svg';
import InstagramIcon from '../assets/instagram-icon.svg';
import GithubIcon from '../assets/github-icon.svg';
import Boostz from '../assets/BOOSTZ.svg';

const Footer = () => {
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        setYear(new Date().getFullYear());
    } ,[]);

    return (
        <footer className="flex flex-col items-center gap-5 px-5 text-gray-500 border-t border-gray-700 py-14">
            <img src={Boostz} className="h-8" alt="Boostz Logo" />
            <div className="flex flex-wrap justify-center gap-16 m-10">
                <Link to="/about" className='transition-colors duration-300 hover:text-white'>About</Link>
                <Link to="/contact" className='transition-colors duration-300 hover:text-white'>Contact</Link>
                <a 
                    href="https://nwc.dev" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="transition-colors duration-300 hover:text-white"
                >
                    NWC
                </a>
                <a 
                    href="https://sparrowtek.com" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="transition-colors duration-300 hover:text-white"
                >
                    SparrowTek
                </a>
            </div>
            <div className="text-[12px]">
                Copyright &copy; {year} SparrowTek. All rights reserved. Jersey City, NJ.
            </div>
            <div className="flex gap-5 mt-2">
                <a href="https://github.com/SparrowTek/Boostz" target="_blank" rel="noreferrer">
                    <img src={GithubIcon} className="h-5" alt="Github Icon" />
                </a>
            </div>
        </footer>
    );
}

export default Footer;
